import React from "react"
import { motion } from "framer-motion"

const Kuala = ({ width }) => (
  <motion.svg width={width || "100%"} fill="currentColor" viewBox="0 0 800 600">
    <motion.path d="M361.1 310.5c0 15-7.6 27-21.3 27-12.2 0-18.1-7-18.1-20.7v-50.4h-19v51.7c0 22.2 11 35 29.8 35 14.1 0 24.9-7.2 30-18.8h.8c-1.9 8.4-2.1 12-2.1 17.5h18.4v-85.5h-18.4v44.2zm200.4-45.4c-18.8 0-30.4 11.6-33.6 27.2l16 3.8c2.7-8.2 8.4-15.4 17.5-15.4s13.5 4.4 13.5 16.7v9.1l-8.2-1.9c-5.3-1.3-9.7-1.9-14.3-1.9-18.1 0-31 9.1-31 25.7 0 16.2 13.5 24.7 26.6 24.7 14.1 0 22.6-6.5 27-15h1.3c-1.3 5.9-1.3 11.8-1.3 13.7h18.1v-56.3c.1-20.7-14-30.4-31.6-30.4zm13.5 55.1c0 11-8.2 17.3-20 17.3-10.3 0-14.8-5.1-14.8-11.6 0-7 5.9-10.3 14.8-10.3 3.4 0 7.8.6 13.7 1.9l6.3 2.1v.6zm-298.8 15c-9.1 0-19-4.9-26.4-24.1l-4.9-12.6c25.9-15.1 37.7-40.2 39.9-64.4l-20.9.2c-2.5 28.9-16.5 47.1-38.2 55.7v-55.7h-19v117.5h19v-44.9c1.1-.4 2.3-.7 3.4-1.1l6.3 16.5c10.3 26.8 28.7 30.6 40.5 30.6 11.2 0 18.8-3.8 23.8-7.8l-6.5-14.8c-4.7 2.8-10 4.9-17 4.9zm159.4-70.1c-18.8 0-30.4 11.6-33.8 27.2l16.2 3.8c2.5-8.2 8.4-15.4 17.5-15.4 8.9 0 13.5 4.4 13.5 16.7v9.1l-8.4-1.9c-5.1-1.1-9.7-1.9-14.1-1.9-18.1 0-31 9.1-31 25.7 0 16.2 13.5 24.7 26.4 24.7 14.3 0 22.6-6.5 27.2-15h1.3c-1.3 6.5-1.3 9.1-1.3 13.7h18.1v-56.3c0-20.7-14.3-30.4-31.6-30.4zm13.5 55.1c0 11-8.4 17.3-20 17.3-10.3 0-15-5.1-15-11.6 0-7 5.9-10.3 15-10.3 3.2 0 7.8.6 13.5 1.9 2.7.6 4 1.1 6.5 2.1v.6zm37.9-83.8h18.8v115.4H487z"></motion.path>
  </motion.svg>
)

export default Kuala
