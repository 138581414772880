import React from "react"

const Kendies = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path
      d="M615.7 342.7c7.1-7.1 15.2-18.2 18.7-26.3 5.1 6.1 17.2 15.2 17.2 18.7 0 1.5-.5 2-2.5 2.5l-33.4 5.1zm-45.9-26.2c-2.5-16.7 2.5-38.4 10.6-38.4 2.5 0 4 2 4 7.1 0 7-5.1 21.7-14.6 31.3zm-57.6-30.3c6.1 0 11.6-5.6 11.6-15.7 0-7.1-3.5-14.1-11.1-14.1-6.6 0-11.1 6.6-11.1 15.7 0 9 3.5 14.1 10.6 14.1zm-69.7 40.4c-9.6 0-5.1-28.8 6.1-28.8 2.5 0 4.5 1.5 6.1 4-.1 10.1-7.2 24.8-12.2 24.8zm-149-33.3c6.1-16.7 16.2-31.8 25.8-31.8 14.1-.1 2.5 31.8-25.8 31.8zm-109.6 80.3c0-13.6-.5-29.3 1-37.9 1.5-7.6 7.6-13.6 8.6-13.6 2.5 0 22.7 42.9 49 42.9 18.7 0 20.7-25.8 12.6-25.8-3.5 0-5.1 2.5-10.6 2.5-8.6 0-25.8-12.1-35.4-34.3 30.8-35.4 78.3-70.7 99-78.8 11.6-4.5 10.6-27.3-.5-23.7-22.2 7.6-82.3 52-124.7 103 2.5-26.3 3-61.6 3-68.7 0-9.1-21.7-6.6-21.7 2-.5 41.9-3 115.1-3 128.8 0 12.1 22.7 16.7 22.7 3.6zm425.7-9.6c15.2-5.1 30.3-7.6 46.5-8.1 18.2-2 22.2-22.7 8.6-34.8-6.1-5.1-17.7-17.7-21.2-23.2-4-6.1-12.6-10.6-18.2 0-2 3.5-23.2 40.4-38.9 40.4-4 0-8.1-2-10.6-6.1 28.8-24.2 37.4-69.7 5.1-69.7-26.3 0-34.3 34.8-27.8 62.6-4 1.5-8.1 2-12.6 2-13.6 0-19.2-15.7-22.7-26.8-2-5.6-13.1-8.1-17.2-.5-3 5.6-11.6 21.2-19.7 21.2-7.6 0-8.1-17.2-7.6-27.8 1.5-27.8 2.5-38.4 4-47 1.5-9.6-14.1-14.1-15.7-5.1-2 12.6-3 32.8-3.5 47-3.5-3.5-7.1-5.1-12.1-5.1-12.1 0-21.7 15.2-24.2 29.8-3.5 3-8.1 5.1-12.1 5.1-5.1 0-9.1-4-14.1-12.1-6.1-9.6-18.7-5.6-22.2.5-4 6.6-8.6 14.1-14.1 21.2-.5-7.6-1-18.7-1-24.2 0-9.1-14.6-7.6-19.2 2-4 8.6-22.7 31.8-39.9 31.8-8.1 0-11.6-7.1-11.6-17.2 0-3 .5-6.6 1-10.6 52.5 5.1 74.7-64.1 32.3-64.1-52 0-74.7 111.6-26.3 111.6 17.7 0 38.4-14.6 48.5-29.3-.5 10.6 3.5 18.7 11.6 18.7 11.1 0 21.2-12.1 29.3-27.3 4 6.1 13.1 14.6 23.7 14.6 6.6 0 11.1-2.5 14.6-5.1 1.5 8.6 6.6 14.6 16.2 14.6 10.6 0 17.7-9.1 21.7-18.7 2 8.1 7.1 15.7 16.7 15.7 12.6 0 20.7-8.6 27.8-17.7 3.5 9.1 13.1 23.2 32.8 23.2 7.6 0 14.6-1.5 21.2-4 5.6 10.6 13.6 17.7 24.2 17.7 8.1 0 13.6-2 17.7-4.5-1.6 7.2 5 10.8 9 9.3z"></path>
    </svg>
)

export default Kendies
