import React from "react"

const Melimelo = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path d="M590.6 353.2c-5.1 0-11.3 2.2-18.3 5.5-3-9.3-3.9-22.3-3.9-37.2 0-29.9 12-73.6 12-102.8 0-19.7-9.4-37.2-34.3-37.2-57.4 0-51.8 74-10.2 74 18.4 0 20.6-19.7 13.7-22.3-.9 7.7-5.1 12.6-12 12.6-24 0-24-57.6 6.4-57.6 14.7 0 20.1 12.7 20.1 32.1 0 25.1-7.9 62.3-9.8 84.4-4.9 16.3-14.3 26.1-27 26.1-11.1 0-15.4-9.6-15.8-20.7 33.7 9 51.3-41.8 13.2-41.8-36.8 0-42.8 74.5-3.4 74.5 14.7 0 25.5-7.4 32.5-22.2-.1 13 .4 31.2 6.5 44.3-23.9 13-56.4 31.9-98.1 31.9-48.8 0-43.7-52.3-17.1-52.3 18.7 0 13.9 29.9-7.7 29.9 13.7 13.7 30.6 0 30.6-15.3 0-10.4-6.9-20.7-25.5-20.7-40.3 0-47.1 73.6 16.2 73.6 50.1 0 82.8-24.4 105.6-40.4 4.6 5.8 11 9.6 20.3 9.6 24.8.2 25.7-28 6-28zm-63.8-77.1c9.2 0 2.1 29.8-15 27.2 1.5-13.7 8-27.2 15-27.2zm60.4 96.7c-5.5 0-9.4-3-12.3-8.1 4.6-3 10.6-6.5 15.8-6.5 9.3.1 10.2 14.6-3.5 14.6z"></path>
    <path d="M669.5 233.2c-20.8 0-28.9 11.4-28.9 24.1 0 15.3 16.9 29 30.6 15.3-21.6 0-22.9-33.4-4.2-33.4 26 0 21.5 60.9-24.7 63.3-.1-17.2-8.9-33.3-26.8-33.3-43.2 0-43.7 74.5-7.7 74.5 19.7 0 29.1-13.7 33.6-31.7 62.2-.2 67.4-78.8 28.1-78.8zm-44.7 67c-3.6-.5-8.3-1.7-13.4-4.6-10.7-6.1-5-17.8 3-17.8 7.8-.1 10.9 10.4 10.4 22.4zm-16.4 33.3c-9.9 0-12.2-19.7-8.5-35.7.1-.5.7-.6 1-.2 5.8 7.9 14.8 11.1 22.7 12.6-2.3 12.2-7.9 23.3-15.2 23.3zM392.7 360c-4.7 0-9.5 1.6-14.6 4.1-1-3.9-1.6-8.8-1.6-14.4 0-10 2.9-26.9 5.1-42.8 2-14.2-15.4-5.9-15.4-5.9-2.2 15.4-4.6 30.1-5 40.6-2.6 9.4-7.6 15.4-12.5 15.4-4.7 0-6.9-3.8-6.9-15.8 0-18.8 9.8-61.6 9.8-90.8 0-19.7-9.4-38.1-34.3-38.1-57.4 0-50.5 74.9-10.2 74.9 18.4 0 20.6-19.7 13.7-22.3-.9 7.7-5.1 12.6-12 12.6-24 0-24-58.4 6.4-58.4 14.7 0 20.1 13.6 20.1 33 0 25.1-7.9 62.3-9.8 84.4-4.9 16.3-14.3 26.1-27 26.1-11.1 0-15.4-9.6-15.8-20.7 33.7 9 51.3-41.8 13.2-41.8-36.8 0-42.8 74.5-3.4 74.5 15 0 25.9-7.7 32.9-23.1 1.6 14.3 8.3 19.2 15 19.2 10.7 0 17.2-7.5 21.2-16.7.9 6.1 2.6 11.8 5.1 16.6-16.4 10.3-36.3 24.7-64.4 27.1v-.1c-46.1 0-52.7-30.8-52-52.9 1-33.6 23.9-82.4-4.9-82.4-11.6 0-20.5 15.3-25.5 29.3 2.1-16.1.4-28.8-11.4-28.8s-18.5 13.9-21.6 23.5l1.1-5.9c4.2-35.9-14.6-50.5-34.3-50.5-54 0-53.1 76.2-16.2 76.2 23.5 0 24-25.7 17.1-29.1 0 13.1-6.9 18.8-13.7 18.8-23.1 0-19.7-59.1 14.6-59.1 17.5 0 20.3 21.1 18.4 36.8l.1.3c-1.8 12.2-11 74.1-8.6 81.9 2.6 8.6 13.3 3.4 13.3 3.4 6-65.1 19.7-86.1 26.6-86.1 12 0-3.3 49.2-4.2 70.7-.9 18 10.2 12 10.2 12 3.4-39.4 18.8-83.1 29.9-83.1 11.1 0-6 41.3-6 75.3 0 28.3 14 64.7 60.8 64.7 29.7 0 55.5-22.4 74.3-36.7 4 5 9.2 8 15.6 8 20.5.1 26.5-23.9 6.8-23.9zm-94.6-52.2c9.2 0 2.1 29.8-15 27.2 1.5-13.7 8-27.2 15-27.2zm91.2 68.5c-4.2 0-8.6-3.4-9.4-7.1 4.6-3 8.7-4.8 12-4.8 10.3-.1 8.6 11.9-2.6 11.9z"></path>
    <path d="M436.4 241.4c10.2 0-3.1 49.3-5.1 70.7-1.7 18 12.8 12 12.8 12 3.4-40.3 16.2-83.1 27.4-83.1 11.1 0-2.6 36.1-2.6 70.2 0 29.9 12 56.5 46.3 56.5 18.8 0 30.8-12 29.1-22.3-6 9.4-16.2 11.1-25.7 11.1-26.6 0-35.1-20.3-35.1-45.4 0-33.6 21.1-80.5-7.7-80.5-11.6 0-18.8 11.8-23.8 25.9 2.1-16.1-1.1-25.5-11.4-25.5-13 0-20.6 18.4-23.5 27.4 4.7-29.5-5.8-40.7-21.1-40.7-26.6 0-30.8 38.5-30.8 53.1 0 6.9 5.1 12 11.1 12 14.4 0 14.8-20.7.6-20.7h-1.4c-1 0-1.7-.8-1.8-1.7-.2-16.8 8.2-35.8 19.6-35.8 10.9 0 11.6 13.5 10.1 28.1-3.1 21.9-9 64.9-7.1 71.2 2.6 8.6 12.4 3.4 12.4 3.4 5.5-54.7 20.8-85.9 27.7-85.9z"></path>
  </svg>
)

export default Melimelo
