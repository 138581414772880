import React from "react"

const Thatcopy = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
  <path d="M154.1 356.1l4.6-17.2c-5.2-.5-7.7-.8-10.3-1.5-4.6-1.3-6.4-3.7-5.2-8l8.1-29.9 17.4 4.6 4.2-15.2-17.5-4.6 5-18.6-18.1-4.9-5 18.6-12.5-3.4-4.2 15.2 12.5 3.4-8.7 32.5c-4.2 15.7 1.5 23.7 14.4 27 4.5 1.2 10 1.8 15.3 2zM211.7 251l-18.1-5-25.9 96.3 18.2 4.9 8.8-32.8c3.6-13.2 10.3-18.8 20.3-16.1 8.1 2.1 11.1 8.4 8.6 18.2l-10.2 38.2 18.6 5 11.5-42.7c4.6-17.8-1.3-30-16.9-34.3-9.3-2.4-17.6-.6-24.9 6.1l10-37.8zm55.4 37.9l10.8 13.2c3.7-5.1 10.8-6.7 19.5-4.3 7.8 2.1 11.4 5.7 10.2 10.1l-.5 1.2c-.5 1.5-1.7 2.1-4.3 1.7l-19.1-3.4c-13.7-2.3-24.6 3.8-27.7 15.8-3.4 12.5 3.8 23.9 17.4 27.6 9.3 2.4 18 .6 24-5.1l-2.4 9.4 18.1 4.9 11.4-42.3c5.1-18.8-2.3-30.6-22.6-36-15.6-4.4-28-1.8-34.8 7.2zm35.9 35.5l-.7 2.7c-2.1 8.1-10.6 12.4-19.5 10-6.1-1.7-9.2-5.2-7.8-10 1-4.2 5-5.9 10.6-5.2l17.4 2.5zm66.4 31.7l4.6-17.2c-5.2-.5-7.7-.8-10.3-1.5-4.6-1.3-6.4-3.7-5.2-8l8.1-29.9 17.4 4.6 4.2-15.2-17.5-4.6 5-18.6-18.1-4.9-5 18.6-12.5-3.4-4.2 15.2 12.5 3.4-8.7 32.5c-4.2 15.7 1.5 23.7 14.4 27 4.5 1.2 9.8 1.8 15.3 2zm58.4-3.5c8.2 2.3 15.8 2.4 24 1.2l1.2-19.9c-8.1 1.7-15 .8-20.1-.5-16.5-4.4-25.5-19.5-20.8-36.5 4.5-17.1 19.9-25.6 36.4-21.2 9.6 2.7 17.4 9.2 22.5 18.7l13.9-15.2c-6.8-11.3-18.3-19-31.2-22.6-27.4-7.3-53.6 7.8-60.9 35.1-7.2 27.4 8.1 53.7 35 60.9z"></path>
    <path d="M473.6 307.1c-5.7 21.5 6.1 40.3 27.4 45.9 21.2 5.7 40.8-4.6 46.6-26 5.7-21.5-6.1-40.3-27.4-45.9-21-5.8-40.7 4.7-46.6 26zm55.3 14.8c-2.9 11.1-12.8 16.2-23.1 13.3-10.3-2.7-16.4-12.1-13.3-23.1 2.9-11.1 12.8-16.2 23.1-13.3 10.2 2.8 16.3 12.2 13.3 23.1zm20.2 47.8l18.1 4.9 10.1-37.5c3.3 8.8 10.3 15.3 19.9 18 18.3 4.9 35-6.5 40.7-27.8 5.8-21.3-3.1-39.6-21.6-44.5-9.5-2.6-18.7-.7-25.8 5.1l2.4-9.4-18.1-4.9-25.7 96.1zm47.3-33.9c-10.7-2.9-16.1-12.2-13.1-23.3 3-10.9 12.4-16.4 23-13.6 10.6 2.9 15.9 12.4 13.1 23.2-3.1 10.9-12.5 16.6-23 13.7zm40.5 36.9l19.1 5.1 69-84.7-19.1-5.1-27.1 33.2-7.3-42.3-21.2-5.7 13.3 66.7-26.7 32.8z"></path>
    <path d="M601.2 420c-14.7 10.1-37.1 19-67.4 14.9-30.4-4.2-48.4-18.6-58.5-32.3-5-6.8-8.1-13.4-9.9-18.3-1-2.5-1.5-4.5-1.9-5.9-.2-.7-.4-1.3-.5-1.8 0-.2-.1-.4-.1-.6v-.3l8.8-1.4 8.8-1.4v.2c0 .2.1.6.2 1.1.2 1 .7 2.4 1.3 4.3 1.3 3.7 3.7 8.7 7.5 13.9 7.5 10.2 21.2 21.8 46.6 25.2 25.5 3.4 43.4-3.9 55.1-11.9 5.8-4 10.2-8.3 13-11.4 1.4-1.5 2.4-2.9 3.1-3.8.4-.5.6-.7.7-1 .1-.1.1-.1.1-.2l7.5 4.8c7.6 4.6 7.5 4.8 7.5 4.8s0 .1-.1.1c-.1.1-.2.2-.4.5-.2.4-.6 1-1.1 1.5-1 1.3-2.3 3-4 5.1-3.7 3.7-9.1 8.9-16.3 13.9z" fillRule="evenodd" clipRule="evenodd"></path>
  </svg>
)

export default Thatcopy
