import React from "react"

const Citizens = () => (
  <svg  width="100%"  fill="currentColor" viewBox="0 0 800 600">
     <path d="M215.9 224.4h15.2V376h-15.2z"></path>
    <path d="M192.6 202.5l69.9 4.5v157.8h15.2V208l53.4 3.4 1-14.6-138.5-8.9z"></path>
    <path d="M307.6 232.5h15.2v137.8h-15.2zm64.5-47.5h7.6v-42.5h62.5v-7.3h-85.4v7.3h15.3z"></path>
    <path d="M404.5 165.6h-7.8v-15.3h-7.6v34.8h7.6v-13.2h7.8v13.2h7.6v-34.9h-7.6zm33.9-9.9v-6.3h-17.6V186h17.6v-6.3h-11.3v-9.1h8.8v-6.3h-8.8v-8.6zM173.3 227c-21.1 25-32.6 56.7-32.6 89.3 0 32.5 11.7 64.2 32.5 88.9l11.8-9.9c-18.6-21.9-28.9-50-28.9-79s10.2-57.3 28.9-79.4l-11.7-9.9zm395.2 122.2l-46.9-118.9h-14.9V407h15.4V273.7l46.9 118h14.6V204.9h-15.1zm32.7-133.5h11.4l1.2-54.4h-13.9zm47.6 90.3c-6.2-19.5-12.6-39.8-12.6-54 0-16 5.8-25.5 15.5-25.5v-15.2c-14.2 0-30.7 10.7-30.7 40.8 0 16.7 6.7 38 13.3 58.7 5.7 18.1 11.7 36.7 11.7 48.7 0 28.4-15.4 28.4-20.2 28.4v15.2c10 0 18.6-3.5 24.6-10 7.3-7.5 10.8-18.9 10.8-33.8 0-14.4-6.1-33.2-12.4-53.3zm-246.7-87h-54.4v15.2h35.8l-38.2 186.7h58.9v-15.3h-40.3zm27 68.9h-10.4l-2.5 15.2h12.9v93.7h52.6v-15.3h-37.4v-78.4h23.4v-15.2h-23.4v-63.2h40v-15.3h-55.2z"></path>
  </svg>
)

export default Citizens
