import React from "react"

const Mailbee = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
<path d="M659 316.4c-1.9 0-3.8 2.9-4.8 4.2-9.6 11.5-16.2 14.5-26.2 14.5-7.2 0-11.2-7.5-12.6-16.6 38.3-.7 42.9-46.4 13.8-46.4-22 0-32.5 22.8-31.8 43.9-1.1 3.1-13.9 22.5-31.6 22.5-7.2 0-11.2-7.5-12.6-16.6 38.3-.7 42.9-46.4 13.8-46.4-17.7 0-28 14.8-31 31.6-4.8-.9-9.8-2.2-14.4-4.3-.2-5.1-3-10.1-9.4-12.2-16.6-5.5-25.4 18.9-7.2 25.7-.8 7.8-4.4 21.5-19.3 21.5-8.3 0-14.4-4.8-18.9-12.1 14.3-17.7 28.1-49.6 28.1-77.8 0-16-8.4-27.6-23-27.6-21 0-31.6 29.3-31.6 60.7 0 14.3 1.9 28.6 5.9 40.8-5.7 7.9-13.7 16.1-22.7 16.1-8.4 0-14.8-4.4-19.3-11.5 14.5-17.6 28.6-49.9 28.6-78.5 0-16-7.2-27.6-21.9-27.6-20.8 0-33 30.5-33 62.5 0 16.1 2 31.2 6.6 43.4-4.2 5.7-10.3 12-17.3 12-6.9 0-10.7-6.3-10.7-16.4 0-14.3 3.4-30.1 3.4-35.8 0-7.2-9.6-3.4-14.2-1.2-1.5.7-2.5 2.1-2.8 3.8-.9 6.2-3 19.3-3 33.6 0 3.9.3 7.4.9 10.6-3.4 3.1-7.4 5.5-11.7 5.5-6.9 0-10.7-6.3-10.7-16.4 0-14.3 3.4-26.6 3.4-32.4 0-7.4-9.9-3.4-14.4-1.1-2.3-2.4-7.9-7.2-15.7-7.2-15.2 0-32.1 10.5-32.1 41 0 19.6 10.2 33.2 21.9 33.2 11.8 0 18.8-7.3 22.8-17 3.7 10.9 11.2 16.8 20.2 16.8 8.5 0 15.9-4 21.7-8.8 4.1 5.8 9.8 8.8 16.3 8.8 12.5 0 22.5-8.5 28.7-15.7 6.8 9.8 16.6 15.6 30 15.6 14.5 0 25.9-10.1 32.3-17.5 6.9 10.7 16.7 17.5 29.9 17.5 29.3 0 36.7-23.5 38.6-37.3 3.3 1 7.7 2 13.3 2.8.8 18 9.8 34.6 26.9 34.6 18.4 0 30.9-12.2 38.3-22.8 4 11.1 12 19.3 24 19.3 19.3 0 32.1-12.1 37.8-25.9 1.7-4.6.5-9.4-3.3-9.4zm-372.5 22.3c-15 0-13-43.6 7-44.3 5.7-.2 9.1 5.9 9.1 5.9s-.7 38.4-16.1 38.4zm123.8-100.9c14.4 0 2.5 52.2-12.9 72.3-7.3-29.6.4-72.3 12.9-72.3zm62.2 0c15 0 2.6 51.3-12.1 71.5-7-29.6-.3-71.5 12.1-71.5zm92.4 52.4c9.4 0 7.1 18.7-11.9 19.1 1.1-10.2 5.2-19.1 11.9-19.1zm62.3-3.5c9.4 0 7.1 18.7-11.9 19.1 1-10.2 5.2-19.1 11.9-19.1z"></path>
    <path d="M297.5 361.6c-1.6 0-3.9 6.7-19.3 6.7-53.7 0-29.9-101.4-27.6-115.8.8-5.2-11.9-12.1-18.4-8.3-4.1 2.5-19 27.5-21.6 33.9 0 0 2.7-19.3 3.8-25.3.7-3.4-4.6-8.9-10.7-8.9-9.5 0-24.7 30.4-27.2 36.7 0 0 4.6-31.1 3.1-31.8-2.3-1.1-12-1.3-16.1 3.4 0 0-7.3 87.4-6.3 98 .1.7.4 1.2.9 1.6 3.9 2.9 12.1 2.7 13.1-.1 2-5.1 2.6-51.2 22.3-78.3.6-.8 1.9-.2 1.7.7-1.9 13.8-8.1 63.5-4.5 72.5 2.3 5.7 14.3 3.5 13.7.4-.6-3 3.4-50.5 23.3-77.8.6-.8 2-.2 1.7.7-3.9 20.6-17.7 113.7 46.5 113.7 12.7 0 21.1-7.4 23.4-13.1 2.2-5.3.7-8.9-1.8-8.9z"></path>
    <ellipse cx="352.3" cy="259.7" rx="12.9" ry="12.9"></ellipse>
</svg>
)

export default Mailbee
