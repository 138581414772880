import React from "react"

const Fratelli = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path d="M351.2 257.1c-16.5 0-34.5 22.3-43.6 43.3-2.6-8.4-9.4-16.1-26.1-7.3 3.8 22.3-9.2 69.3-9.2 79 0 9.2 11.8 11.4 24.4 4.6 0-29.4 11.4-88.2 52.9-94.1 10.1.1 19.8-25.5 1.6-25.5z"></path>
    <path d="M373.2 300.7c-2.7-4.2-7-7.1-14-7.1-36.5 0-43.7 78.1-8.4 78.1 10.3 0 18.2-9.7 22.6-17 1.6 14 13.6 21.6 23.6 11.9-4.6-20.2-1.2-52.1 2.1-67.2-8.2-10.6-20.9-6.2-25.9 1.3zm-14.8 52.5c-13.5 0-8-44.9 7.6-44.9 3.8 0 5.9 2.6 7.1 6.3-.4 9.6-5.1 38.6-14.7 38.6zm-107.1-47.1c3.7-20.9 7-39.6 10.3-46.5 9.9 1.7 19.4 2.9 27.9 2.9 35.3 0 81.1-22.7 81.1-56.2 0-41.2-68.8-31.5-68.8 0 0 11.4 9.2 12.1 18 10.1 4.2-13.5 14.7-27.7 28.2-27.7 26.1 0-2.9 54.1-63.8 54.1-23.1 0-63.8-8-85.6-8-76.4 0-78.1 97.9-29 97.9 40.3 0 62.1-66.8 32.7-66.8-7.1 0-11.4 3.8-14.7 8 2.6 13 3.8 50.4-15.1 50.4-26.1 0-23.5-72.6 34.9-72.6 10.8 0 24.4 2.5 38.2 5-8.9 9.2-13.1 20.9-19 52.9l-.4 2.4c-11.2 3-19.1 8.3-17.3 20.3 2.5-2.5 6.7-4.9 14.6-5.7-4.7 26.1-10.3 51.8-17.5 61.1 9.2 5.9 21.8-2.6 27.3-12.1 6-9.9 10.7-30.4 14.8-51.6 15.9-1.6 25.4-13.5 21-29-4.1 3.5-9.9 8-17.8 11.1zm397.7-23c-.4-6.3-7.6-13.5-21-9.7-2.1 26.8-6.3 41.2-6.3 54.1 0 10.9 2.9 26.5 25.2 21.8-1.7-2.9-3.3-6.7-3.3-14.3-.1-14.6 5.8-45.2 5.4-51.9zM612.9 226c-.4-5.5-7.6-12.6-21-8.8-1.2 28.2-12.6 75.6-12.6 102 0 17.6 4.2 38.6 25.6 33.6-2.6-2.6-4.2-13-3.8-25.2.9-22.6 12.6-91.9 11.8-101.6zm31.4 2.5c-7.6 0-14.3 6.7-15.1 15.6-.9 9.2 4.2 15.9 11.8 15.9 7.1 0 13.5-6.7 14.7-15.9 1.2-8.9-4.2-15.6-11.4-15.6z"></path>
    <path d="M482.7 336.8c.8-12.6 5.5-43.6 8.6-68.4 12.4-1.3 24.7-3.3 35.5-6.4 22.3-6.3 38.6-19.3 38.6-33.6-20.7 13.2-46.9 20-72.2 23.6.9-8.1 1.2-14.2.9-16.9-.9-5.5-7.6-12.6-21-8.8-.3 8.2-1.4 17.9-2.9 28.3-7.2.6-14.1.9-20.7 1.2.9-8.2 1.5-14.4 1.3-16.9-.4-5.5-8-12.6-21.4-8.8-.2 7.7-1.3 16.6-2.7 26.3-7.4.1-13.3.3-17.1.7-19.3 2.1-32.7 13.5-25.2 25.2 2.1-2.6 16.4-10.1 34.4-10.9 1.5-.1 3.3-.1 5.5-.2-3.5 21.4-7.6 44.1-7.6 61.1 0 19.3 5 38.6 26.1 33.6-2.1-2.6-4.2-13-3.8-25.2.5-13.2 5.2-45 8.4-69.8 6.3-.1 13-.3 20.1-.7-3.4 20.7-7.1 42.1-7.1 58.2 0 19.3 5 38.6 26.1 33.6-2.1-2.5-4.7-12.9-3.8-25.2z"></path>
    <path d="M542.3 273c-50 0-52.5 85.2-9.7 85.2 31.5 0 39.1-32.7 20.2-32.7-3.3 0-5.9 1.2-9.2 3.3 0 9.2-3.3 19.7-9.2 19.7-6.6 0-8.4-13.7-7-28.8 43.5 8.2 54.8-46.7 14.9-46.7zm.5 8.5c9.6 0 2.9 32-14.6 31.1 2.4-16 7.9-31.1 14.6-31.1z"></path>
  </svg>
)

export default Fratelli
