import React from "react"

const Lapine = ({width}) => (
  <svg width={width} fill="currentColor" viewBox="0 0 800 600">
    <path d="M198.823 222.586H177V337.241H198.823V222.586Z"></path>
    <path d="M215.076 272.052L231.156 283.517C233.781 276.802 241.165 273.034 251.667 273.034C260.855 273.034 265.942 275.983 265.942 281.224V282.534C265.942 284.5 264.629 285.483 261.676 285.81L239.196 287.776C223.28 289.25 212.942 299.241 212.942 313.491C212.942 328.56 224.428 339.043 240.673 339.043C251.667 339.043 260.691 334.293 265.942 326.267V337.405H287.601V286.793C287.601 264.353 275.951 253.543 251.667 253.543C233.125 253.543 220.162 260.095 215.076 272.052ZM265.942 300.879V304.155C265.942 313.819 257.902 321.026 247.072 321.026C239.852 321.026 235.422 317.914 235.422 312.345C235.422 307.431 239.196 304.155 245.595 303.336L265.942 300.879Z"></path>
    <path d="M305.993 370H327.652V325.448C334.052 334.293 343.897 339.371 355.219 339.371C377.206 339.371 392.302 321.681 392.302 296.293C392.302 270.741 377.042 253.216 354.891 253.216C343.569 253.216 333.888 257.966 327.652 266.647V255.345H305.993V370ZM348.491 318.241C335.857 318.241 326.996 309.397 326.996 296.293C326.996 283.19 335.857 274.345 348.491 274.345C361.126 274.345 369.986 283.353 369.986 296.293C369.986 309.233 361.126 318.241 348.491 318.241Z"></path>
    <path d="M418.446 245.681C426.486 245.681 432.393 239.784 432.393 231.922C432.393 223.897 426.486 218 418.446 218C410.569 218 404.662 223.897 404.662 231.922C404.662 239.784 410.569 245.681 418.446 245.681ZM429.275 255.345H407.616V337.241H429.275V255.345Z"></path>
    <path d="M449.568 337.241H471.227V298.095C471.227 282.534 477.298 274.181 488.784 274.181C498.794 274.181 504.044 280.241 504.044 291.871V337.241H526.196V285.974C526.196 265.172 515.694 253.379 497.317 253.379C486.323 253.379 476.806 258.293 470.571 267.793V255.345H449.568V337.241Z"></path>
    <path d="M540.465 296.293C540.465 320.698 556.709 339.207 584.44 339.207C600.192 339.207 610.365 333.966 617.093 328.397L608.068 310.543C602.325 315.457 594.613 318.897 584.768 318.897C575.251 318.897 565.406 314.147 562.781 303.172L562.617 302.845H623V296.948C623 270.578 607.576 253.379 582.471 253.379C556.709 253.379 540.465 272.052 540.465 296.293ZM599.372 287.121H562.452C564.586 277.129 571.477 271.724 581.815 271.724C592.316 271.724 598.387 278.276 599.372 287.121Z"></path>
  </svg>
)

export default Lapine
