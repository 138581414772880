import React from "react"

const North = ({width}) => (
  <svg width={width} fill="currentColor" viewBox="0 0 800 600">
    <path d="M248.739 327.789C248.349 333.34 248.375 339.256 241.613 342.607C235.007 345.932 224.786 344.518 220.624 339.178C215.215 332.292 210.091 324.7 204.526 317.396C200.442 312.003 196.281 306.584 191.782 301.532C190.377 299.935 186.034 293.023 183.537 294.201C181.249 295.222 181.613 323.13 181.821 332.214C181.847 337.895 180.26 342.633 174.227 343.471C167.803 344.309 162.133 340.172 161.483 333.418C159.454 310.799 159.558 288.232 161.483 265.639C161.873 260.953 165.748 256.686 170.403 255.613C179.714 253.492 186.996 255.456 192.458 263.676C199.48 274.226 207.23 284.908 215.215 295.301C216.359 296.793 218.7 300.406 220.885 303.076C222.107 304.594 224.552 303.992 224.994 302.107C225.826 298.469 226.45 293.782 226.606 291.767C227.308 283.651 227.048 275.457 227.048 267.315C227.048 258.205 229.883 254.644 237.087 255.325C244.214 256.005 247.725 259.932 248.505 268.729C248.947 273.834 249.701 286.217 249.675 298.521C249.779 310.825 249.103 323.051 248.739 327.789Z"></path>
    <path d="M311.496 348C283.694 347.529 263.694 330.172 259.819 304.306C256.62 280.64 274.435 258.44 298.05 253.283C305.696 251.738 313.707 251.607 321.379 252.759C345.098 256.634 362.393 275.195 363.095 298.364C363.564 322.03 346.789 340.931 323.121 346.351C319.272 347.188 315.371 347.503 311.496 348ZM311.626 330.564C327.205 330.355 338.18 316.506 338.154 298.914C338.05 282.63 326.815 267.42 311.782 267.341C301.171 267.341 288.479 282.525 288.479 299.542C288.583 317.108 300.339 330.564 311.626 330.564Z"></path>
    <path d="M372.562 298.94C372.589 288.52 372.797 278.101 373.759 267.681C374.591 259.252 382.706 255.351 391.132 254.67C404.916 253.545 418.674 253.859 432.511 254.775C435.449 254.958 438.492 255.979 441.379 257.105C464.37 266.53 467.595 283.049 454.565 300.615C447.101 310.904 446.165 311.846 451.938 322.527C453.655 325.747 455.605 328.81 456.932 332.109C459.533 338.575 456.542 342.974 449.286 344.204C442.758 345.277 437.608 343.183 434.123 337.502C431.21 332.737 428.635 327.632 425.592 322.763C423.954 320.145 422.367 316.663 419.871 315.538C416.022 313.81 409.91 312.396 406.113 313.391C403.59 314.045 402.628 318.967 401.821 322.344C400.859 326.454 401.743 331.01 401.327 335.303C400.625 342.345 397.27 344.832 390.066 344.125C381.639 343.55 374.539 339.387 373.915 332.999C372.875 321.637 372.589 310.302 372.615 298.94C372.615 298.94 372.589 298.94 372.562 298.94ZM418.492 294.96C419.481 294.856 422.419 294.83 425.176 294.123C430.872 292.657 433.057 289.803 432.849 283.075C432.641 276.583 430.846 272.917 425.046 271.844C420.235 270.954 415.163 270.902 410.3 271.399C405.176 271.923 402.471 275.614 401.769 280.797C400.443 290.458 405.202 294.934 418.492 294.96Z"></path>
    <path d="M548.687 299.332C548.687 288.913 548.947 278.52 549.831 268.1C550.611 259.435 554.538 255.534 562.419 255.011C569.935 254.566 572.614 257.812 572.718 266.896C573.082 289.463 573.29 289.384 595.969 289.28C598.387 289.28 600.78 289.332 603.199 289.175C611.209 288.651 614.252 285.772 614.876 277.604C615.188 273.546 614.876 269.436 614.954 265.352C615.136 257.76 618.205 254.461 625.123 254.539C632.171 254.644 637.243 258.571 638.257 265.692C639.636 275.902 639.87 286.06 639.974 296.243C640.104 307.815 639.87 319.386 638.699 330.957C637.737 340.068 633.602 344.021 625.565 344.518C618.101 344.937 615.006 341.063 614.954 331.952C614.928 327.397 615.084 322.815 614.694 318.286C614.174 312.16 610.481 308.705 604.317 308.469C597.347 308.207 590.351 308.181 583.381 308.417C576.515 308.652 572.874 312.579 572.666 319.386C572.536 324.177 572.848 328.994 572.692 333.785C572.432 341.246 568.921 344.754 562.263 344.283C555.579 343.785 551.183 339.832 550.403 332.659C549.025 321.559 548.687 299.332 548.687 299.332Z"></path>
    <path d="M508.531 253.23C516.801 253.1 525.072 252.995 533.342 253.361C536.541 253.309 563.771 254.461 566.242 256.477C569.181 258.702 572.458 262.812 572.744 265.901C573.004 268.545 569.753 272.027 566.944 273.31C563.849 274.593 535.371 273.232 531.6 273.677C522.601 274.488 519.662 277.106 519.324 286.321C518.778 301.846 518.908 317.396 518.596 332.921C518.414 340.984 515.215 344.858 508.895 345.33C501.977 345.827 497.867 342.476 496.515 335.172C495.995 332.319 495.865 329.413 495.787 326.533C495.371 314.255 495.345 301.977 495.371 289.698C495.423 276.897 492.822 274.122 480.156 274.043C478.023 274.043 475.865 274.174 473.784 273.938C469.519 273.415 464.838 272.734 465.202 267.367C465.592 261.817 468.687 257.236 474.746 255.665C477.945 254.827 481.222 254.409 484.421 254.173C492.458 253.571 500.494 253.361 508.531 253.23Z"></path>
  </svg>
)

export default North
