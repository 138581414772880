import React from "react"

const Swipp = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path d="M424.9 275.9c6.6.5 11.7-4.1 12.2-10.7.6-6.7-3.5-11.7-10.1-12.2s-11.2 3.5-12.2 10.1c-1.1 6.7 3.5 12.2 10.1 12.8z"></path>
    <path d="M561.2 282.5c-8.3 0-14.9 5.2-20.3 12.6l.8-3.4c2-7.2-13.1-6.1-15.7-2-.6.8-1.9 4.8-3.5 10.5-5.6 11.8-13 21.2-20.3 27.8 7.4-20.1 3.9-45.4-16.9-45.4-8.2 0-14.8 5.1-20 12.3l1.2-4.7c1.6-6.1-14.3-4.1-16.2-.5-.9 1.8-3.5 10.3-6.7 21.2-5.3 13-12 29.6-19.2 29.6-8.6 0 5.6-42.2 6.1-47.8 1.6-12.7-17.3-7.2-18.3-2l-1.6 5.3c-.5 1.8-1.8 3.3-3.5 4-3.1 1.4-7.2 2.3-10.6 2.7.1-6-1.7-17-12.2-17-13 0-16 19.2-4.8 27-2.7 14-8.2 27.3-15.5 27.3-12.2 0-.5-38.1.5-43.8 2.6-12.7-16.2-7.2-17.3-2-1.4 7.3-4.3 18.3-5.9 29.2-2.7 7.9-6.7 14.9-12.5 14.9-7.2 0-6.6-14.3 0-38.7 4.6-17.3-15.2-12.6-17.8-5.1-4.1 13-12.5 25.9-21.4 35.4v-1.4c0-32-34.6-36.1-34.6-62 0-41.6 57.4-68.1 90.4-68.1 34.2 0 7.2 54.4-31.6 54.4-9.6 0-12.7-6.1-7.2-11.7 2.6-2.6 2-4.1.5-4.1-4.1 0-13.1 5.6-13.1 13.8 0 10.1 8.6 14.7 20.8 14.7 55.4 0 96.5-85.4 29-85.4-37.2 0-110.3 30-110.3 86 0 37.2 36.1 41.6 36.1 65.6 0 5.6-4.1 13.1-10.7 13.1-5.1 0-5.1-6.1-2.6-11.2 2-4.1 1-5.6-1.6-5.6-4.6 0-13.1 6.6-13.1 16.8 0 7.7 4.1 15.3 16.2 15.3h2.2c.4 0 .7 0 1-.1.3 0 .4 0 .6-.1.4 0 .8-.1 1.2-.1h.1c16.5-2.3 31.7-16 42.1-30.5-.4 15.1 3.5 28.3 18.6 28.3 8 0 14-4.6 18.5-11 2.3 6.6 7.5 11 17 11 20.1 0 30.1-21.7 35.4-42.1 3.1-.4 6.7-1.3 10.6-2.9.4-.1.7.1.6.5-5.3 21.4-7.8 44.3 10.9 44.3 6.6 0 11.8-3.8 16.5-9.6-3.6 13.5-6.6 25.2-7.3 29-2 10.7 14.3 8.1 18.3 2l8.7-34.6c6.6-21.7 16.1-46.8 28.5-46.8 14.7 0 10.1 44.2-13.8 41.2-4.1-.5-8.1 13.1 7.7 13.1 14 0 29.8-11.4 39.4-24.9-5.9 21-12 44.2-13 49.9-2 10.7 14.3 8.1 18.3 2l8.5-33.6c6.7-21.8 16.2-47.7 28.7-47.7 14.7 0 8.6 41.2-14.3 41.2-4.1 0-7.7 13.1 8.1 13.1 34.2.4 45-69.2 10.3-69.2z"></path>
    </svg>
)

export default Swipp
